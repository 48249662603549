// import * as cornerstone from "@cornerstonejs/core";
// import * as cornerstone from "../cornerstone3D/packages/core/src";
import { cornerstone } from "./init-cornerstone";
import ptScalingMetaDataProvider from "./pt-scaling-metadata-provider";

const { calibratedPixelSpacingMetadataProvider } = cornerstone.utilities;

export default function initProviders() {
  cornerstone.metaData.addProvider(
    ptScalingMetaDataProvider.get.bind(ptScalingMetaDataProvider),
    10000
  );
  cornerstone.metaData.addProvider(
    calibratedPixelSpacingMetadataProvider.get.bind(
      calibratedPixelSpacingMetadataProvider
    ),
    11000
  );
}
