import dicomParser from "dicom-parser";
// import * as cornerstone from "@cornerstonejs/core";
import { cornerstone, cornerstoneDICOMImageLoader } from "./init-cornerstone";
import type { WADORSMetaData } from "../../../cornerstone3D/packages/dicomImageLoader/src/types";
// import * as cornerstone from "../cornerstone3D/packages/core/src";
// import * as cornerstoneTools from "@cornerstonejs/tools";
// @ts-ignore
// import cornerstoneDICOMImageLoader from "@cornerstonejs/dicom-image-loader";
// import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader/dist/dynamic-import/cornerstoneDICOMImageLoader.min.js';
// import cornerstoneDICOMImageLoader from "../cornerstone3D/packages/dicomImageLoader/dist/dynamic-import/cornerstoneDICOMImageLoader.min.js";
// import cornerstoneDICOMImageLoader from '../cornerstone3D/packages/dicomImageLoader/dist/cornerstoneDICOMImageLoaderNoWebWorkers.bundle.min.js';

// window.cornerstone = cornerstone;
// window.cornerstoneTools = cornerstoneTools;
const { preferSizeOverAccuracy, useNorm16Texture } =
  cornerstone.getConfiguration().rendering;

export function initCornerstoneDICOMImageLoader() {
  // @ts-ignore
  cornerstoneDICOMImageLoader.external.cornerstone = cornerstone;
  // @ts-ignore
  cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;
  // @ts-ignore
  cornerstoneDICOMImageLoader.configure({
    // beforeSend(xhr: XMLHttpRequest) {
    //   xhr.setRequestHeader("Range", "bytes=0-99999");
    // },
    useWebWorkers: true,
    decodeConfig: {
      convertFloatPixelDataToInt: false,
      use16BitDataType: preferSizeOverAccuracy || useNorm16Texture,
    },
    progressivelyRender: true,
    initialBytes: (metaData: WADORSMetaData, imageId: string) => {
      const rows = (metaData["00280010"].Value as any)[0];
      const columns = (metaData["00280011"].Value as any)[0];
      const fullRes = rows * columns;
      const minInitialBytes = Math.floor(fullRes * 0.005);
      return Math.max(5_000, minInitialBytes);
    },
    // totalRanges: 3,
  });

  let maxWebWorkers = 1;

  if (navigator.hardwareConcurrency) {
    maxWebWorkers = Math.min(navigator.hardwareConcurrency, 7);
  }

  var config = {
    maxWebWorkers,
    startWebWorkersOnDemand: false,
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
        strict: false,
      },
    },
  };

  // @ts-ignore
  cornerstoneDICOMImageLoader.webWorkerManager.initialize(config);
}
export function toggleProgressiveRender(progressivelyRender: boolean) {
  // @ts-ignore
  cornerstoneDICOMImageLoader.configure({
    progressivelyRender,
  });
  console.log(`Progressively render: ${progressivelyRender}`);
}
