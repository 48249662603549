// import { utilities as csUtils } from "@cornerstonejs/core";
import { cornerstone } from "./init-cornerstone";
const { utilities: csUtils } = cornerstone;

const scalingPerImageId: any = {};

function addInstance(imageId: string, scalingMetaData: any) {
  const imageURI = csUtils.imageIdToURI(imageId);
  scalingPerImageId[imageURI] = scalingMetaData;
}

function get(type: string, imageId: string) {
  if (type === "scalingModule") {
    const imageURI = csUtils.imageIdToURI(imageId);
    return scalingPerImageId[imageURI];
  }
}

export default { addInstance, get };
