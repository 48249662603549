// import { volumeLoader } from "@cornerstonejs/core";
import { cornerstone, streamingImageVolumeLoader } from "./init-cornerstone";
const { volumeLoader } = cornerstone;
// import { volumeLoader } from "../cornerstone3D/packages/core/src";
const {
  cornerstoneStreamingImageVolumeLoader,
  cornerstoneStreamingDynamicImageVolumeLoader,
} = streamingImageVolumeLoader;

export default function initVolumeLoader() {
  volumeLoader.registerUnknownVolumeLoader(
    // @ts-ignore
    cornerstoneStreamingImageVolumeLoader
  );
  volumeLoader.registerVolumeLoader(
    "cornerstoneStreamingImageVolume",
    // @ts-ignore
    cornerstoneStreamingImageVolumeLoader
  );
  volumeLoader.registerVolumeLoader(
    "cornerstoneStreamingDynamicImageVolume",
    // @ts-ignore
    cornerstoneStreamingDynamicImageVolumeLoader
  );
}
