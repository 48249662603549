import * as cornerstone from "../../../cornerstone3D/packages/core/src";
import { Types } from "../../../cornerstone3D/packages/core/src";
// import * as dicomImageLoader from '../cornerstone3D/packages/dicomImageLoader'
// import cornerstoneDICOMImageLoader from "../../../cornerstone3D/packages/dicomImageLoader/dist/cornerstoneDICOMImageLoaderNoWebWorkers.bundle.min.js";
import cornerstoneDICOMImageLoader from "../../../cornerstone3D/packages/dicomImageLoader/dist/dynamic-import/cornerstoneDICOMImageLoader.min.js";
import * as streamingImageVolumeLoader from "../../../cornerstone3D/packages/streaming-image-volume-loader/src";
// import * as cornerstoneTools from "../cornerstone3D/packages/tools/src";
import {
  init,
  PanTool,
  WindowLevelTool,
  StackScrollMouseWheelTool,
  StackScrollTool,
  ZoomTool,
  ToolGroupManager,
  Enums,
  addTool,
} from "../../../cornerstone3D/packages/tools/src";

import { stackPrefetch } from "../../../cornerstone3D/packages/tools/src/utilities";

const cornerstoneTools = {
  init,
  PanTool,
  WindowLevelTool,
  StackScrollMouseWheelTool,
  StackScrollTool,
  ZoomTool,
  ToolGroupManager,
  Enums,
  addTool,
  stackPrefetch
};

export {
  cornerstone,
  cornerstoneDICOMImageLoader,
  streamingImageVolumeLoader,
  cornerstoneTools,
};
export type { Types };
