// import { metaData } from "@cornerstonejs/core";
import { cornerstone, cornerstoneDICOMImageLoader } from "./init-cornerstone";
const { metaData } = cornerstone;

// @ts-ignore
// import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
// @ts-ignore
// import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader/dist/dynamic-import/cornerstoneDICOMImageLoader.min.js';
// import cornerstoneDICOMImageLoader from '../cornerstone3D/packages/dicomImageLoader/dist/dynamic-import/cornerstoneDICOMImageLoader.min.js';
// import cornerstoneDICOMImageLoader from "../cornerstone3D/packages/dicomImageLoader/dist/cornerstoneDICOMImageLoaderNoWebWorkers.bundle.min.js";

/**
 * preloads imageIds metadata in memory
 **/
async function prefetchMetadataInformation(imageIdsToPrefetch: string[]) {
  for (let i = 0; i < imageIdsToPrefetch.length; i++) {
    // @ts-ignore
    await cornerstoneDICOMImageLoader.wadouri.loadImage(imageIdsToPrefetch[i])
      .promise;
  }
}

function getFrameInformation(imageId: string) {
  if (imageId.includes("wadors:")) {
    const frameIndex = imageId.indexOf("/frames/");
    const imageIdFrameless =
      frameIndex > 0 ? imageId.slice(0, frameIndex + 8) : imageId;
    return {
      frameIndex,
      imageIdFrameless,
    };
  } else {
    const frameIndex = imageId.indexOf("&frame=");
    let imageIdFrameless =
      frameIndex > 0 ? imageId.slice(0, frameIndex + 7) : imageId;
    if (!imageIdFrameless.includes("&frame=")) {
      imageIdFrameless = imageIdFrameless + "&frame=";
    }
    return {
      frameIndex,
      imageIdFrameless,
    };
  }
}
/**
 * Receives a list of imageids possibly referring to multiframe dicom images
 * and returns a list of imageid where each imageid referes to one frame.
 * For each imageId representing a multiframe image with n frames,
 * it will create n new imageids, one for each frame, and returns the new list of imageids
 * If a particular imageid no refer to a mutiframe image data, it will be just copied into the new list
 * @returns new list of imageids where each imageid represents a frame
 */
function convertMultiframeImageIds(imageIds: string[]) {
  const newImageIds: string[] = [];
  imageIds.forEach((imageId) => {
    const { imageIdFrameless } = getFrameInformation(imageId);
    const instanceMetaData = metaData.get("multiframeModule", imageId);
    if (
      instanceMetaData &&
      instanceMetaData.NumberOfFrames &&
      instanceMetaData.NumberOfFrames > 1
    ) {
      const NumberOfFrames = instanceMetaData.NumberOfFrames;
      for (let i = 0; i < NumberOfFrames; i++) {
        const newImageId = imageIdFrameless + (i + 1);
        newImageIds.push(newImageId);
      }
    } else newImageIds.push(imageId);
  });
  return newImageIds;
}

export { convertMultiframeImageIds, prefetchMetadataInformation };
