/**
 * 
 * @param numOffsets Number of total resolutions in htj2k image
 * @param baseWidth Largest (base) dimension from which to calculate subresolutions
 * @param baseHeight
 * @returns Array of dimension tuples [w, h], sorted smallest to largest
 */
export function getResolutionsFromNumOffsets(
  numOffsets: number,
  baseWidth: number,
  baseHeight: number
) {
  const subresDimensions: number[][] = [[baseWidth, baseHeight]];
  for (let i = 1; i < numOffsets; i += 1) {
    let dim = [];
    dim = [
      Math.ceil(subresDimensions[i - 1][0] / 2),
      Math.ceil(subresDimensions[i - 1][1] / 2),
    ];

    subresDimensions.push(dim);
  }
  return subresDimensions.reverse();
}
