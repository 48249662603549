// import { RenderingEngine, Types, Enums, volumeLoader } from "@cornerstonejs/core";
import {
  cornerstone,
  cornerstoneTools,
  cornerstoneDICOMImageLoader,
} from "../utilities/init-cornerstone";
import type { Types } from "../utilities/init-cornerstone";
const {
  RenderingEngine,
  Enums,
  volumeLoader,
  init: csRenderInit,
} = cornerstone;
import {
  initCornerstoneDICOMImageLoader,
  toggleProgressiveRender,
} from "../utilities/init-dicom-image-loader";
import setCtTransferFunctionForVolumeActor from "../utilities/set-ct-transfer-function-for-volume-actor";
import initVolumeLoader from "../utilities/init-volume-loader";
import initProviders from "../utilities/init-providers";
import createImageIdsAndCacheMetaData from "../utilities/create-imageids-and-cache-metadata";
import { getResolutionsFromNumOffsets } from "../../../../shared-utils/get-resolutions-from-num-offsets";
// import { init as csRenderInit } from "@cornerstonejs/core";
const { init: csToolsInit } = cornerstoneTools;

const { ViewportType } = Enums;

const {
  PanTool,
  WindowLevelTool,
  StackScrollMouseWheelTool,
  StackScrollTool,
  ZoomTool,
  ToolGroupManager,
  Enums: csToolsEnums,
  stackPrefetch,
} = cornerstoneTools;

const { MouseBindings } = csToolsEnums;

const toolGroupId = "STACK_TOOL_GROUP_ID";

// Add tools to cs3d
cornerstoneTools.addTool(PanTool);
cornerstoneTools.addTool(WindowLevelTool);
cornerstoneTools.addTool(StackScrollMouseWheelTool);
cornerstoneTools.addTool(StackScrollTool);
cornerstoneTools.addTool(ZoomTool);

// Define a tool group, which defines how mouse events map to tool commands for
// Any viewport using the group
const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);
if (!toolGroup) {
  throw new Error("Could not create tool group");
}

// Add tools to the tool group
toolGroup.addTool(WindowLevelTool.toolName);
toolGroup.addTool(StackScrollTool.toolName);
toolGroup.addTool(PanTool.toolName);
toolGroup.addTool(ZoomTool.toolName);
toolGroup.addTool(StackScrollMouseWheelTool.toolName, { loop: false });

// Set the initial state of the tools, here all tools are active and bound to
// Different mouse inputs
toolGroup.setToolActive(WindowLevelTool.toolName, {
  bindings: [
    {
      mouseButton: MouseBindings.Primary, // Left Click
    },
  ],
});
toolGroup.setToolActive(PanTool.toolName, {
  bindings: [
    {
      mouseButton: MouseBindings.Auxiliary, // Middle Click
    },
  ],
});
toolGroup.setToolActive(ZoomTool.toolName, {
  bindings: [
    {
      mouseButton: MouseBindings.Secondary, // Right Click
    },
  ],
});
// As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
// hook instead of mouse buttons, it does not need to assign any mouse button.
toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);

// Instantiate a rendering engine
const renderingEngineId = "myRenderingEngine";
let renderingEngine: cornerstone.RenderingEngine;

async function setup() {
  // Init Cornerstone and related libraries
  initProviders();
  initCornerstoneDICOMImageLoader();
  initVolumeLoader();
  await csRenderInit();
  await csToolsInit();

  // Monitor network speed
  setInterval(() => {
    let detect = (
      document.getElementById("auto-set-progressive") as HTMLInputElement
    ).checked;

    let progressivelyRender = (
      document.getElementById("progressively-render") as HTMLInputElement
    ).checked;

    let message;

    let shouldProgressivelyRender = progressivelyRender;
    if (detect) {
      // @ts-ignore
      if (window.currentMeanDownSpeed < 10) {
        shouldProgressivelyRender = true;
        message = "Slow connection detected, turning on progressive rendering";
      } else {
        shouldProgressivelyRender = false;
        message = "Fast connection detected, turning off progressive rendering";
      }
      if (shouldProgressivelyRender !== progressivelyRender) {
        console.log(message);
        toggleProgressiveRender(shouldProgressivelyRender);
        progressivelyRender = shouldProgressivelyRender;
      }
    }
    (
      document.getElementById("progressively-render") as HTMLInputElement
    ).checked = shouldProgressivelyRender;
  }, 1000);

  (
    document.getElementById("progressively-render") as HTMLInputElement
  ).onchange = () => {
    toggleProgressiveRender(
      (document.getElementById("progressively-render") as HTMLInputElement)
        .checked
    );
  };

  renderingEngine = new RenderingEngine(renderingEngineId);
}

/**
 * Runs the demo
 */
async function runCTVol() {
  // cornerstone.eventTarget.addEventListener(cornerstone.EVENTS.IMAGE_LOAD_STREAM_PARTIAL, (event) => {
  //   console.log(event)
  // })
  // cornerstone.eventTarget.addEventListener(cornerstone.EVENTS.IMAGE_LOAD_STREAM_COMPLETE, (event) => {
  //   console.log(event)
  // })
  // cornerstone.eventTarget.addEventListener(cornerstone.EVENTS.IMAGE_LOAD_STREAM_UPDATED_IMAGE, (event) => {
  //   console.log(event)
  // })

  // ======== Set up page ======== //
  const element = document.getElementById(
    "viewport-ct-volume"
  ) as HTMLDivElement;
  if (!element) throw new Error("Element required");
  element.style.width = "500px";
  element.style.height = "500px";
  // ============================= //

  // Get Cornerstone imageIds and fetch metadata into RAM
  let imageIds = await createImageIdsAndCacheMetaData({
    StudyInstanceUID:
      // "1.3.6.1.4.1.14519.5.2.1.7009.2403.334240657131972136850343327463",
      // "1.2.840.113654.2.55.187766322555605983451267194286230980878",
      "1.2.276.0.7230010.3.1.2.503984659.29012.1458240712.872803",
    SeriesInstanceUID:
      // "1.3.6.1.4.1.14519.5.2.1.7009.2403.226151125820845824875394858561",
      // "1.2.840.113654.2.55.122344168497038128022524906545138736420",
      "1.2.276.0.7230010.3.1.3.503984659.29012.1458240712.872804",
    // wadoRsRoot: "https://d3t6nz73ql33tx.cloudfront.net/dicomweb",
    wadoRsRoot: "https://litevna.app/dicomweb",
  });

  // Instantiate a rendering engine
  // const renderingEngineId = "myRenderingEngine";
  // const renderingEngine = new RenderingEngine(renderingEngineId);

  // Create a volume viewport
  const viewportId = "CT_VOL";
  const viewportInput = {
    viewportId,
    // type: ViewportType.STACK,
    type: ViewportType.ORTHOGRAPHIC,
    element,
    defaultOptions: {
      orientation: Enums.OrientationAxis.SAGITTAL,
      background: <Types.Point3>[0.2, 0, 0.2],
    },
  };

  renderingEngine.enableElement(viewportInput);

  // Set the tool group on the viewport
  toolGroup?.addViewport(viewportId, renderingEngineId);

  // Get the viewport that was created
  // const viewport = <Types.IStackViewport>(
  const viewport = <Types.IVolumeViewport>(
    renderingEngine.getViewport(viewportId)
  );

  // Define a unique id for the volume
  const volumeName = "CT_VOLUME_ID"; // Id of the volume less loader prefix
  const volumeLoaderScheme = "cornerstoneStreamingImageVolume"; // Loader id which defines which volume loader to use
  const volumeId = `${volumeLoaderScheme}:${volumeName}`; // VolumeId with loader id + volume id

  // Define a volume in memory
  const volume = await volumeLoader.createAndCacheVolume(volumeId, {
    imageIds,
  });

  // Set the volume to load
  volume.load();

  // Set the volume on the viewport
  viewport.setVolumes([
    { volumeId, callback: setCtTransferFunctionForVolumeActor },
  ]);

  // Render the image
  viewport.render();
}

async function runCTStack() {
  /** CT stack */
  const element = document.getElementById(
    "viewport-ct-stack"
  ) as HTMLDivElement;
  if (!element) throw new Error("Element required");
  element.style.width = "500px";
  element.style.height = "500px";

  // studies/1613192914.117696130591632945097905843929317463272/series/1613192914.195826032876302042831626442747043781795/instances/1613192914.106101989343321792592123920267066036949/frames/1

  // Get Cornerstone imageIds and fetch metadata into RAM
  const imageIds = await createImageIdsAndCacheMetaData({
    StudyInstanceUID:
      "1.2.276.0.7230010.3.1.2.503984659.29012.1458240712.872803",
    SeriesInstanceUID:
      "1.2.276.0.7230010.3.1.3.503984659.29012.1458240712.872804",
    wadoRsRoot: "https://litevna.app/dicomweb",
  });

  // Create a stack viewport
  const viewportId = "CT_STACK";
  const viewportInput = {
    viewportId,
    type: ViewportType.STACK,
    element,
    defaultOptions: {
      background: <Types.Point3>[0.2, 0, 0.2],
    },
  };

  renderingEngine.enableElement(viewportInput);

  // Set the tool group on the viewport
  toolGroup?.addViewport(viewportId, renderingEngineId);

  // Get the stack viewport that was created
  const viewport = <Types.IStackViewport>(
    renderingEngine.getViewport(viewportId)
  );

  // Define a stack containing a single image
  const stack = imageIds;

  // Set the stack on the viewport
  await viewport.setStack(stack);

  // Enable prefetch
  stackPrefetch.enable(element);

  // Render the image
  viewport.render();
}

async function runCxr() {
  /** CXR stack */
  const element = document.getElementById("viewport-cxr") as HTMLDivElement;
  if (!element) throw new Error("Element required");
  element.style.width = "500px";
  element.style.height = "500px";

  // studies/1613192914.117696130591632945097905843929317463272/series/1613192914.195826032876302042831626442747043781795/instances/1613192914.106101989343321792592123920267066036949/frames/1

  // Get Cornerstone imageIds and fetch metadata into RAM
  const imageIds = await createImageIdsAndCacheMetaData({
    StudyInstanceUID: "1613192914.117696130591632945097905843929317463272",
    SeriesInstanceUID: "1613192914.195826032876302042831626442747043781795",
    // StudyInstanceUID: "9999.4873689601340706578125708148444354948",
    // SeriesInstanceUID: "9999.21671276797191441480984915045348094693",
    wadoRsRoot: "https://litevna.app/dicomweb",
  });

  // Instantiate a rendering engine
  // const renderingEngineId = "myRenderingEngine";
  // const renderingEngine = new RenderingEngine(renderingEngineId);

  // Create a stack viewport
  const viewportId = "CXR_STACK";
  const viewportInput = {
    viewportId,
    type: ViewportType.STACK,
    element,
    defaultOptions: {
      background: <Types.Point3>[0.2, 0, 0.2],
    },
  };

  renderingEngine.enableElement(viewportInput);

  // Set the tool group on the viewport
  toolGroup?.addViewport(viewportId, renderingEngineId);

  // Get the stack viewport that was created
  const viewport = <Types.IStackViewport>(
    renderingEngine.getViewport(viewportId)
  );

  // Define a stack containing a single image
  const stack = [imageIds[0]];

  // Set the stack on the viewport
  await viewport.setStack(stack);

  // Set the VOI of the stack
  // viewport.setProperties({ voiRange: ctVoiRange });

  // Render the image
  viewport.render();
}

async function runCxrSubresolutions() {
  // Get Cornerstone imageId and fetch metadata into RAM
  const imageIds = await createImageIdsAndCacheMetaData({
    StudyInstanceUID: "1613192914.117696130591632945097905843929317463272",
    SeriesInstanceUID: "1613192914.195826032876302042831626442747043781795",
    wadoRsRoot: "https://litevna.app/dicomweb",
    // wadoRsRoot: "http://localhost:8787/dicomweb",
  });

  // Get subresolutions
  // @ts-ignore
  let instanceMetaData = cornerstoneDICOMImageLoader.wadors.metaDataManager.get(
    imageIds[0]
  );
  const subresolutionOffsets: number[] = instanceMetaData["AAA10010"]?.Value[0];
  const imageHeight = Number(instanceMetaData["00280010"].Value[0]); // Rows
  const imageWidth = Number(instanceMetaData["00280011"].Value[0]); // Columns

  if (!subresolutionOffsets) {
    throw new Error("No subresolution offsets in metadata");
  }

  const subresDimensions = getResolutionsFromNumOffsets(
    subresolutionOffsets.length,
    imageWidth,
    imageHeight
  );

  const subresImageIds = subresDimensions.map(
    (dim) => `${imageIds[0]}?fsiz=${dim[0]},${dim[1]}`
  );

  // Something weird happens when trying to load the biggest (full) resolution,
  // probably layout or memory related?
  subresImageIds.slice(0, 5).forEach(async (imageId, i) => {
    /** CXR stack */
    const element = document.createElement("div");
    const container = document.getElementById("subresolutions");
    container?.appendChild(element);
    if (!element) throw new Error("Element required");
    element.style.width = subresDimensions[i][0] + "px";
    element.style.height = subresDimensions[i][1] + "px";

    // studies/1613192914.117696130591632945097905843929317463272/series/1613192914.195826032876302042831626442747043781795/instances/1613192914.106101989343321792592123920267066036949/frames/1

    // Instantiate a rendering engine
    // const renderingEngineId = "myRenderingEngine";
    // const renderingEngine = new RenderingEngine(renderingEngineId);

    // Create a stack viewport
    const viewportId = "CXR_STACK_" + i;
    const viewportInput = {
      viewportId,
      type: ViewportType.STACK,
      element,
      defaultOptions: {
        background: <Types.Point3>[0.2, 0, 0.2],
      },
    };

    renderingEngine.enableElement(viewportInput);

    // Set the tool group on the viewport
    toolGroup?.addViewport(viewportId, renderingEngineId);

    // Get the stack viewport that was created
    const viewport = <Types.IStackViewport>(
      renderingEngine.getViewport(viewportId)
    );

    // Define a stack containing a single image
    const stack = [imageId];

    // Set the stack on the viewport
    await viewport.setStack(stack);

    // Set the VOI of the stack
    // viewport.setProperties({ voiRange: ctVoiRange });

    // Render the image
    viewport.render();
  });
}

async function runMultiframe() {
  /** US multiframe stack */
  const element = document.getElementById("multiframe") as HTMLDivElement;
  if (!element) throw new Error("Element required");
  element.style.width = "500px";
  element.style.height = "500px";

  // studies/1613192914.117696130591632945097905843929317463272/series/1613192914.195826032876302042831626442747043781795/instances/1613192914.106101989343321792592123920267066036949/frames/1

  // Get Cornerstone imageIds and fetch metadata into RAM
  const imageIds = await createImageIdsAndCacheMetaData({
    StudyInstanceUID: "9999.4873689601340706578125708148444354948",
    SeriesInstanceUID: "9999.21671276797191441480984915045348094693",
    wadoRsRoot: "https://litevna.app/dicomweb",
  });

  // Instantiate a rendering engine
  // const renderingEngineId = "myRenderingEngine";
  // const renderingEngine = new RenderingEngine(renderingEngineId);

  // Create a stack viewport
  const viewportId = "MULTIFRAME_STACK";
  const viewportInput = {
    viewportId,
    type: ViewportType.STACK,
    element,
    defaultOptions: {
      background: <Types.Point3>[0.2, 0, 0.2],
    },
  };

  renderingEngine.enableElement(viewportInput);

  // Set the tool group on the viewport
  toolGroup?.addViewport(viewportId, renderingEngineId);

  // Get the stack viewport that was created
  const viewport = <Types.IStackViewport>(
    renderingEngine.getViewport(viewportId)
  );

  // Define a stack containing a single image
  const stack = [imageIds[1]];

  // Set the stack on the viewport
  await viewport.setStack(stack);

  // Set the VOI of the stack
  // viewport.setProperties({ voiRange: ctVoiRange });

  // Render the image
  viewport.render();
}

setup().then(() => {
  document.getElementById("load-ct-volume")?.addEventListener("click", () => {
    runCTVol();
  });
  document.getElementById("load-ct-stack")?.addEventListener("click", () => {
    runCTStack();
  });
  document.getElementById("load-cxr")?.addEventListener("click", () => {
    runCxr();
  });
  document.getElementById("load-cxr-sub")?.addEventListener("click", () => {
    runCxrSubresolutions();
  });
  document.getElementById("load-us")?.addEventListener("click", () => {
    runMultiframe();
  });
});
